<template>
  <div v-if="basketPriceOld > 0" class="item">
    <span>Товары:</span>
    <span>{{ formatPrice(basketPriceOld) }} руб.</span>
  </div>
  <div v-else class="item">
    <span>Товары:</span>
    <span>{{ formatPrice(basketPrice) }} руб.</span>
  </div>
  <div v-if="basketAmount > 0" class="item">
    <span>Скидка:</span>
    <span>- {{ formatPrice(basketAmount) }} руб.</span>
  </div>
  <div
    v-if="promoCodeType === 'gift-card' && promoCodeAmount > 0"
    class="item discount gift-card"
  >
    <img
      src="@/assets/images/info.svg"
      @mouseover="$parent.promoCodeInfo = true"
      @mouseenter="promoCodeInfoFalse"
    />
    <div :class="{ hover: $parent.promoCodeInfo }">
      <img src="@/assets/images/shop-close.svg" />
      <p>
        Текущим подарочным сертификатом можно воспользоваться один раз,
        неиспользованный номинал по сертификату возврату не подлежит.
      </p>
      <a href="/gift-card-rules" target="_blank">
        Подарочные сертификаты - Правила
      </a>
    </div>
    <span>Подарочный сертификат:</span>
    <span>- {{ formatPrice(promoCodeAmount) }} руб.</span>
  </div>
  <div v-else-if="promoCodeAmount > 0" class="item discount">
    <span>Промокод {{ promoCode }}:</span>
    <span>- {{ formatPrice(promoCodeAmount) }} руб.</span>
  </div>
  <div v-else-if="isEnterPromoCode" class="promo-code">
    <input
      v-model="inputPromoCode"
      ref="inputPromoCode"
      placeholder="Промокод или сертификат"
      type="text"
      @keyup="clearInputPromoCode"
    />
    <button @click.stop="enterPromoCode">Активировать</button>
  </div>
  <div v-if="this.$parent.isWriteOffBonus" class="item discount">
    <span>Оплата бонусами:</span>
    <span>- {{ $parent.writeOffBonus }} руб.</span>
  </div>
  <div v-if="$parent.selectDelivery && !isOnlyVirtual" class="item">
    <span v-if="$parent.selectDelivery.type === 1">
      Доставка в г. {{ $parent.selectDelivery.info_point.address_city }}:
    </span>
    <span v-else>Доставка в г. {{ $parent.selectDelivery.city }}:</span>
    <span v-if="$parent.checkedExpressCourier">299 руб.</span>
    <span v-else>{{ formatPrice($parent.selectDelivery.cost) }} руб.</span>
  </div>
  <div v-else-if="!isOnlyVirtual" class="item">
    <span v-if="city">Доставка в г. {{ city }}:</span>
    <span v-else>Доставка:</span>
    <span>0 руб.</span>
  </div>
  <div class="item total">
    <span>Итоговая сумма:</span>
    <span>{{ formatPrice(basketTotalAmount) }} руб.</span>
  </div>
  <span
    v-if="isEnterPromoCode === false && promoCode === null && !isOnlyVirtual"
    class="promo-code"
    @click.stop="clickEnterPromoCode"
  >
    У меня есть промокод или сертификат
  </span>
  <ErrorTooltipBlock
    v-if="errorTooltip"
    :header="'Ошибка'"
    :text="errorTooltip"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import productMixin from "@/mixins/productMixin";
import ErrorTooltipBlock from "@/components/ErrorTooltipBlock.vue";
import api from "@/api";

export default {
  mixins: [mainMixin, productMixin],
  components: {
    ErrorTooltipBlock,
  },
  props: {
    products: Object,
    city: String,
    isOnlyVirtual: Boolean,
  },
  data() {
    return {
      basketPrice: 0,
      basketPriceOld: 0,
      basketAmount: 0,
      basketTotalAmount: 0,
      isEnterPromoCode: false,
      inputPromoCode: "",
      promoCodeObj: {},
      promoCode: null,
      errorTooltip: null,
      promoCodeAmount: null,
      promoCodeType: "simple",
    };
  },
  created() {
    let promoCode = localStorage.getItem("cart_promoCode");
    if (promoCode) {
      this.inputPromoCode = promoCode;
      this.enterPromoCode();
    } else this.totalBasket();
  },
  methods: {
    promoCodeInfoFalse() {
      let that = this;
      setTimeout(function () {
        that.$parent.promoCodeInfo = false;
      }, 15000);
    },
    async enterPromoCode() {
      this.inputPromoCode = this.inputPromoCode.toUpperCase();
      localStorage.removeItem("cart_promoCode");
      if (this.inputPromoCode === "") return false;
      try {
        const result = await api.shop.getPromoCodeValue(this.inputPromoCode);
        console.log(result);
        if (result["data"]["name"]) {
          this.activePromoCode(result);
        } else {
          if (result["data"]["error"] === "count") {
            this.errorTooltip =
              "Превышено максимальное количество использований промокода " +
              this.inputPromoCode;
          } else {
            this.errorTooltip =
              "Промокод " +
              this.inputPromoCode +
              " не существует, или истек действия этого промокода";
          }
          this.totalBasket();
        }
      } catch (error) {
        console.log(error);
        this.errorTooltip =
          "Промокод " +
          this.inputPromoCode +
          " не существует, или истек действия этого промокода";
        this.totalBasket();
      }
    },
    activePromoCode(result) {
      this.promoCodeObj = result["data"];
      this.promoCode = result["data"]["name"];
      this.isEnterPromoCode = false;
      this.errorTooltip = null;
      localStorage.setItem("cart_promoCode", this.promoCode);
      this.totalBasket();
      if (result["data"]["is_gift_card"]) {
        this.promoCodeType = "gift-card";
      }
    },
    clickEnterPromoCode() {
      this.isEnterPromoCode = true;
      this.$nextTick(() => this.$refs.inputPromoCode.focus());
    },
    async clearInputPromoCode() {
      this.inputPromoCode = this.inputPromoCode.toUpperCase();
      if (this.inputPromoCode === "") return false;
      try {
        const result = await api.shop.getPromoCodeValueCheck(
          this.inputPromoCode
        );
        if (result["data"]["name"]) this.activePromoCode(result);
      } catch (error) {
        console.log(error);
      }
    },
    totalBasket() {
      this.countBasket = 0;
      this.basketPrice = 0;
      this.basketPriceOld = 0;
      this.basketAmount = 0;
      let amountPromoCode = 0;
      let basketPrice = 0;
      for (let item in this.products) {
        item = this.products[item];
        if (item.product.id !== 102) {
          basketPrice += item.priceTotal;
        }
      }
      this.$parent.maxFreePackage = Math.ceil(basketPrice / 2000);
      let promoFalse = null;
      for (let item in this.products) {
        item = this.products[item];
        if (item.priceOld) {
          this.basketPriceOld += item.priceOld * item.quantity;
          item.priceOldTotal = item.priceOld * item.quantity;
        } else {
          this.basketPriceOld += item.price * item.quantity;
        }
        item.priceTotal = item.price * item.quantity;
        if (item.product.id === 102) {
          item.priceOldTotal = item.priceTotal;
          let quantity = item.quantity - this.$parent.maxFreePackage;
          if (quantity < 0) quantity = 0;
          item.priceTotal = quantity * item.price;
        }
        this.basketPrice += item.priceTotal;
        this.countBasket += item.quantity;
        if (this.promoCode) {
          let isActive = true;
          if (item.type === 5 || item.type === 6) isActive = false;
          if (!this.promoCodeObj["is_product_discount"] && item.priceOld) {
            isActive = false;
          }
          if (
            this.promoCodeObj["exclude_products"].includes(
              item["product"]["id"]
            )
          ) {
            isActive = false;
          }
          if (this.promoCodeObj["exclude_categories"].length > 0) {
            for (let category in item["product"]["categories"]) {
              category = item["product"]["categories"][category]["id"];
              if (this.promoCodeObj["exclude_categories"].includes(category)) {
                isActive = false;
              }
            }
          }
          if (
            item.isPromo &&
            !item.promoCodes.includes(this.promoCodeObj["id"])
          ) {
            isActive = false;
            promoFalse = item.promoName;
          }
          if (isActive) amountPromoCode += item.priceTotal;
        }
      }
      if (promoFalse) {
        localStorage.removeItem("cart_promoCode");
        this.isEnterPromoCode = true;
        this.errorTooltip =
          "Промокод '" +
          this.promoCode +
          "' не может быть применен на продукты учавствующие акции '" +
          promoFalse +
          "'";
      }
      if (this.basketPrice === this.basketPriceOld) {
        this.basketPriceOld = 0;
      } else {
        this.basketAmount = this.basketPriceOld - this.basketPrice;
      }
      let basket = document.querySelector("header .basket-button span");
      basket.innerHTML = this.countBasket;
      this.basketTotalAmount = this.basketPrice;
      if (this.promoCode) {
        if (
          !this.promoCodeObj["is_product_discount"] &&
          amountPromoCode === 0
        ) {
          localStorage.removeItem("cart_promoCode");
          this.isEnterPromoCode = true;
          this.errorTooltip =
            "Промокод " +
            this.inputPromoCode +
            " нельзя активировать для товаров со скидками. ";
        } else if (amountPromoCode > this.promoCodeObj["min_amount_order"]) {
          if (this.promoCodeObj["type"] === 1) {
            this.promoCodeAmount =
              amountPromoCode * this.promoCodeObj["amount"];
            this.promoCodeAmount = this.promoCodeAmount / 100;
          } else if (amountPromoCode > this.promoCodeObj["amount"]) {
            this.promoCodeAmount = this.promoCodeObj["amount"];
          } else this.promoCodeAmount = amountPromoCode;
          if (this.promoCodeAmount < 0) this.promoCodeAmount = 0;
          this.promoCodeAmount = this.promoCodeAmount.toFixed(0);
          this.promoCodeAmount = Number(this.promoCodeAmount);
          this.basketTotalAmount -= this.promoCodeAmount;
          if (this.basketTotalAmount < 0) this.basketTotalAmount = 0;
          this.isEnterPromoCode = false;
          this.errorTooltip = null;
        } else if (this.promoCodeObj["min_amount_order"]) {
          localStorage.removeItem("cart_promoCode");
          this.isEnterPromoCode = true;
          this.errorTooltip =
            "Промокод " +
            this.inputPromoCode +
            " можно активировать для заказов от " +
            this.promoCodeObj["min_amount_order"] +
            " руб.";
        }
      }
      if (this.$parent.checkedExpressCourier) {
        this.basketTotalAmount += 299;
      } else if (this.$parent.selectDelivery) {
        this.basketTotalAmount += this.$parent.selectDelivery.cost;
      }
      if (this.$parent.lpLevel) {
        this.$parent.writeOffBonus =
          (this.$parent.lpLevel["percent_write_off"] * this.basketTotalAmount) /
          100;
        this.$parent.writeOffBonus = Math.ceil(this.$parent.writeOffBonus);
        if (this.$parent.writeOffBonus > this.$parent.lpBalance) {
          this.$parent.writeOffBonus = Math.ceil(this.$parent.lpBalance);
        }
        if (this.$parent.isWriteOffBonus) {
          this.basketTotalAmount -= this.$parent.writeOffBonus;
        }
      }
      this.$parent.basketTotalAmount = this.basketTotalAmount;
    },
  },
};
</script>
