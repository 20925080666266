<template>
  <div v-if="!isProduct && page.author" class="author-block">
    <span class="image">
      <img :src="page.author.avatar.image_object" />
    </span>
    <span class="text">
      {{ locale.TEXTS.authorText }}:
      <b>{{ page.author.name }}</b>
      <span>·</span>{{ dateFormat(page.created_at) }}
    </span>
  </div>
  <div class="blocks">
    <div
      v-for="item in blocks"
      :key="item"
      :class="{
        'alignment-1': item.alignment === 1,
        'alignment-2': item.alignment === 2,
        'alignment-3': item.alignment === 3,
        'is-desktop': item.is_display_desktop,
        'is-tablet': item.is_display_tablet,
        'is-mobile': item.is_display_mobile,
      }"
      class="block-item"
    >
      <div
        v-if="item.type === 16"
        class="columns"
        :class="'count-' + Number(item.content)"
      >
        <div
          class="column-item"
          v-for="column in Number(item.content)"
          :key="column"
          :class="{
            'desktop-justification-1':
              columns[item.id][column][0].desktop_justification === 1,
            'desktop-justification-2':
              columns[item.id][column][0].desktop_justification === 2,
            'desktop-justification-3':
              columns[item.id][column][0].desktop_justification === 3,
            'tablet-justification-1':
              columns[item.id][column][0].tablet_justification === 1,
            'tablet-justification-2':
              columns[item.id][column][0].tablet_justification === 2,
            'tablet-justification-3':
              columns[item.id][column][0].tablet_justification === 3,
            'mobile-justification-1':
              columns[item.id][column][0].mobile_justification === 1,
            'mobile-justification-2':
              columns[item.id][column][0].mobile_justification === 2,
            'mobile-justification-3':
              columns[item.id][column][0].mobile_justification === 3,
            'tablet-order-index-1':
              columns[item.id][column][0].tablet_order_index === 1,
            'tablet-order-index-2':
              columns[item.id][column][0].tablet_order_index === 2,
            'tablet-order-index-3':
              columns[item.id][column][0].tablet_order_index === 3,
            'tablet-order-index-4':
              columns[item.id][column][0].tablet_order_index === 4,
            'tablet-order-index-5':
              columns[item.id][column][0].tablet_order_index === 5,
            'tablet-order-index-6':
              columns[item.id][column][0].tablet_order_index === 6,
            'mobile-order-index-1':
              columns[item.id][column][0].mobile_order_index === 1,
            'mobile-order-index-2':
              columns[item.id][column][0].mobile_order_index === 2,
            'mobile-order-index-3':
              columns[item.id][column][0].mobile_order_index === 3,
            'mobile-order-index-4':
              columns[item.id][column][0].mobile_order_index === 4,
            'mobile-order-index-5':
              columns[item.id][column][0].mobile_order_index === 5,
            'mobile-order-index-6':
              columns[item.id][column][0].mobile_order_index === 6,
          }"
        >
          <div
            v-for="block in columns[item.id][column]"
            :key="block"
            class="column-block"
            :class="{
              'alignment-1': block.alignment === 1,
              'alignment-2': block.alignment === 2,
              'alignment-3': block.alignment === 3,
              'is-desktop': block.is_display_desktop,
              'is-tablet': block.is_display_tablet,
              'is-mobile': block.is_display_mobile,
            }"
          >
            <EditorElementBlock
              :block="block"
              :sourcePage="sourcePage"
              :articleId="articleId"
            />
          </div>
        </div>
      </div>
      <EditorElementBlock
        v-else
        :block="item"
        :sourcePage="sourcePage"
        :articleId="articleId"
      />
    </div>
    <div
      v-if="page.id === 23 && votingUserType"
      class="alignment-1 is-desktop is-tablet is-mobile block-item"
    >
      <div class="voting-block">
        <p class="headline-1">
          Челлендж идет прямо <br />
          <span class="text">сейчас</span>
          <span class="time">
            <span id="time_day"></span>
            <span>дней</span>
          </span>
          <span class="time">
            <span id="time_hours"></span>
            <span>часов</span>
          </span>
          <span class="time">
            <span id="time_minute"></span>
            <span>минут</span>
          </span>
        </p>
        <div class="voting-result result-1" v-if="votingUserType === 1">
          <div>
            <p class="headline-2">Ваш прогноз:</p>
            <p class="headline-3">{{ votingUserText }}</p>
            <p class="headline-3">Поделитесь вашим прогнозом:</p>
            <div class="share">
              <a
                href="https://vk.com/share.php?url=https://ultrabalance.ru/1111"
                target="_blank"
              >
                <img src="@/assets/images/shop-vk.svg" />
              </a>
              <a
                :href="
                  'https://t.me/share/url?url=ultrabalance.ru/1111/&amp;text=' +
                  getUrl(votingUserText)
                "
                target="_blank"
              >
                <img src="@/assets/images/shop-telegram.svg" />
              </a>
            </div>
          </div>
          <img src="@/assets/images/voting-3.jpg" alt="" />
        </div>
        <div class="voting-result result-2" v-if="votingUserType === 2">
          <img src="@/assets/images/voting-2.jpg" alt="" />
          <div>
            <p class="headline-2">Ваш прогноз:</p>
            <p class="headline-3">{{ votingUserText }}</p>
            <p class="headline-3">Поделитесь вашим прогнозом:</p>
            <div class="share">
              <a
                href="https://vk.com/share.php?url=https://ultrabalance.ru/1111"
                target="_blank"
              >
                <img src="@/assets/images/shop-vk.svg" />
              </a>
              <a
                :href="
                  'https://t.me/share/url?url=ultrabalance.ru/1111/&amp;text=' +
                  getUrl(votingUserText)
                "
                target="_blank"
              >
                <img src="@/assets/images/shop-telegram.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="page.id === 23"
      class="alignment-1 is-desktop is-tablet is-mobile block-item"
    >
      <div class="voting-block">
        <p class="headline-1">
          Челлендж идет прямо <br />
          <span class="text">сейчас</span>
          <span class="time">
            <span id="time_day"></span>
            <span>дней</span>
          </span>
          <span class="time">
            <span id="time_hours"></span>
            <span>часов</span>
          </span>
          <span class="time">
            <span id="time_minute"></span>
            <span>минут</span>
          </span>
        </p>
        <p class="headline-2">Пройдет ли Виктор дистанцию?</p>
        <p class="headline-3">
          Угадайте наиболее точный исход челленджа и получите ценные подарки
        </p>
        <div class="voting-items">
          <div
            class="voting-item voting-error"
            @mouseleave="votingImage = 1"
            @mouseover="votingImage = 3"
          >
            <button @click="isPopupCancel = true">Это невозможно</button>
          </div>
          <div class="voting-item">
            <img
              src="@/assets/images/voting-1.jpg"
              :class="{ 'd-none': votingImage !== 1 }"
            />
            <img
              src="@/assets/images/voting-2.jpg"
              :class="{ 'd-none': votingImage !== 2 }"
            />
            <img
              src="@/assets/images/voting-3.jpg"
              :class="{ 'd-none': votingImage !== 3 }"
            />
          </div>
          <div
            class="voting-item voting-success"
            @mouseleave="votingImage = 1"
            @mouseover="votingImage = 2"
          >
            <button @click="isPopupSuccess = true">Всё получится!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="shop-popup voting-cancel-popup voting-popup" v-if="isPopupCancel">
    <div class="content">
      <img
        @click="isPopupCancel = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <div v-if="votingCancelSelected === 1">
        <h5>На каком километре плавание <br />Виктор закончит челлендж</h5>
        <div class="voting-option selected">
          <img src="@/assets/images/voting-popup-1.jpg" />
          <span>
            <span>Общее расстояние плавание 10 км</span>
            <span>{{ votingCancelValue }} км</span>
          </span>
        </div>
        <div class="voting-range voting-range-1">
          <input type="range" v-model="votingCancelValue" min="0" max="10" />
          <span>
            <span>0</span>
            <span>3</span>
            <span>6</span>
            <span>10</span>
          </span>
        </div>
        <button @click="clickVotingCancel()">Продолжить</button>
      </div>
      <div v-else-if="votingCancelSelected === 2">
        <h5>На каком километре велоэтапе <br />Виктор закончит челлендж</h5>
        <div class="voting-option selected">
          <img src="@/assets/images/voting-popup-2.jpg" />
          <span>
            <span>Общее расстояние велоэтапа 1001 км</span>
            <span>{{ votingCancelValue }} км</span>
          </span>
        </div>
        <div class="voting-range voting-range-2">
          <input type="range" v-model="votingCancelValue" min="0" max="1001" />
          <span>
            <span>0</span>
            <span>330</span>
            <span>660</span>
            <span>1001</span>
          </span>
        </div>
        <button @click="clickVotingCancel()">Продолжить</button>
      </div>
      <div v-else-if="votingCancelSelected === 3">
        <h5>На каком километре бега <br />Виктор закончит челлендж</h5>
        <div class="voting-option selected">
          <img src="@/assets/images/voting-popup-3.jpg" />
          <span>
            <span>Общее расстояние бега 100 км</span>
            <span>{{ votingCancelValue }} км</span>
          </span>
        </div>
        <div class="voting-range voting-range-3">
          <input type="range" v-model="votingCancelValue" min="0" max="100" />
          <span>
            <span>0</span>
            <span>33</span>
            <span>66</span>
            <span>100</span>
          </span>
        </div>
        <button @click="clickVotingCancel()">Продолжить</button>
      </div>
      <div v-else>
        <h5>На каком этапе закончится <br />челлендж</h5>
        <div class="voting-option" @click="votingCancelSelected = 1">
          <img src="@/assets/images/voting-popup-1.jpg" />
          <span>
            <span>Плавание</span>
            <span>Общее расстояние этапа 10 км</span>
          </span>
        </div>
        <div class="voting-option" @click="votingCancelSelected = 2">
          <img src="@/assets/images/voting-popup-2.jpg" />
          <span>
            <span>Велосипед</span>
            <span>Общее расстояние этапа 1001 км</span>
          </span>
        </div>
        <div class="voting-option" @click="votingCancelSelected = 3">
          <img src="@/assets/images/voting-popup-3.jpg" />
          <span>
            <span>Бег</span>
            <span>Общее расстояние этапа 100 км</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="shop-popup voting-success-popup voting-popup"
    v-if="isPopupSuccess"
  >
    <div class="content">
      <img
        @click="isPopupSuccess = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Сколько времени займет <br />преодоление челленджа</h5>
      <div class="voting-option selected">
        <img src="@/assets/images/voting-popup-1.jpg" />
        <span>
          <span>Общее время, с учетом остановок</span>
          <span>{{ votingSuccessText1 }}</span>
        </span>
      </div>
      <div class="voting-range">
        <input type="range" v-model="votingSuccessValue1" min="1" max="120" />
        <span>
          <span></span>
          <span>1 день</span>
          <span>2 дня</span>
          <span>3 дня</span>
          <span>4 дня</span>
          <span>5 дней</span>
        </span>
      </div>
      <div class="voting-option selected">
        <img src="@/assets/images/voting-popup-2.jpg" />
        <span>
          <span>Время в движении</span>
          <span>{{ votingSuccessText2 }}</span>
        </span>
      </div>
      <div class="voting-range">
        <input type="range" v-model="votingSuccessValue2" min="1" max="120" />
        <span>
          <span></span>
          <span>1 день</span>
          <span>2 дня</span>
          <span>3 дня</span>
          <span>4 дня</span>
          <span>5 дней</span>
        </span>
      </div>
      <button @click="clickVotingSuccess()">Продолжить</button>
    </div>
  </div>
  <SuccessTooltipBlock v-if="tooltipText" :header="tooltipText" />
  <AuthRegBlock v-if="isOpenAuth" :authText="authText" :toStay="true" />
  <ProfileNameBlock
    v-if="isOpenProfileName"
    :profile="profile"
    :isCheckout="false"
    :isPartner="true"
    :isLastName="true"
    :isDisplayEmail="false"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import pageMixin from "@/mixins/pageMixin";
import productMixin from "@/mixins/productMixin";
import EditorElementBlock from "@/components/EditorElementBlock.vue";
import SuccessTooltipBlock from "@/components/SuccessTooltipBlock.vue";
import ProfileNameBlock from "@/components/ProfileNameBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import Locale from "@/api/locale";
import api from "@/api";
import "@/assets/css/blog.css";

export default {
  mixins: [mainMixin, profileMixin, pageMixin, productMixin],
  components: {
    EditorElementBlock,
    SuccessTooltipBlock,
    AuthRegBlock,
    ProfileNameBlock,
  },
  props: {
    page: Object,
    isProduct: Boolean,
    sourcePage: Number,
  },
  data() {
    return {
      blocks: [],
      columns: {},
      locale: Locale,
      articleId: null,
      votingImage: 1,
      isPopupSuccess: false,
      isPopupCancel: false,
      votingCancelSelected: null,
      votingCancelValue: 1,
      votingSuccessText1: "1 час",
      votingSuccessText2: "1 час",
      votingSuccessValue1: 1,
      votingSuccessValue2: 1,
      tooltipText: null,
      isOpenAuth: false,
      isOpenProfileName: false,
      votingUserType: null,
      votingUserText: null,
    };
  },
  watch: {
    isOpenAuth(isOpen) {
      if (this.authToken && !isOpen) {
        if (this.isPopupCancel) this.clickVotingCancel();
        if (this.isPopupSuccess) this.clickVotingSuccess();
      }
    },
    isPopupSuccess(isOpen) {
      this.votingSuccessText1 = "1 час";
      this.votingSuccessText2 = "1 час";
      this.votingSuccessValue1 = 1;
      this.votingSuccessValue2 = 1;
      this.isOpenPopup(isOpen);
    },
    isPopupCancel(isOpen) {
      this.votingCancelSelected = null;
      this.votingCancelValue = 1;
      this.isOpenPopup(isOpen);
    },
    votingSuccessValue1(value) {
      this.votingSuccessText1 = "";
      let day = Math.floor(value / 24);
      if (day > 0) {
        value -= 24 * day;
        if (day === 1) this.votingSuccessText1 += day + " день ";
        else if (day === 5) this.votingSuccessText1 += day + " дней ";
        else this.votingSuccessText1 += day + " дня ";
      }
      if (value > 0) {
        if (value === 1 || value === 21) {
          this.votingSuccessText1 += value + " час";
        } else if (value > 1 && value < 5) {
          this.votingSuccessText1 += value + " часа";
        } else if (value > 21) this.votingSuccessText1 += value + " часа";
        else this.votingSuccessText1 += value + " часов";
      }
    },
    votingSuccessValue2(value) {
      this.votingSuccessText2 = "";
      let day = Math.floor(value / 24);
      if (day > 0) {
        value -= 24 * day;
        if (day === 1) this.votingSuccessText2 += day + " день ";
        else if (day === 5) this.votingSuccessText2 += day + " дней ";
        else this.votingSuccessText2 += day + " дня ";
      }
      if (value > 0) {
        if (value === 1 || value === 21) {
          this.votingSuccessText2 += value + " час";
        } else if (value > 1 && value < 5) {
          this.votingSuccessText2 += value + " часа";
        } else if (value > 21) this.votingSuccessText2 += value + " часа";
        else this.votingSuccessText2 += value + " часов";
      }
    },
  },
  methods: {
    async clickVotingCancel() {
      if (this.authToken) {
        let profile = this.profile;
        if (!profile.firstName || !profile.lastName) {
          this.isOpenProfileName = true;
        }
        let data = {
          type: 1,
          selected: this.votingCancelSelected,
          value: this.votingCancelValue,
          auth_token: this.authToken,
        };
        try {
          const result = await api.shop.postVoting1111(data);
          if (result["data"]["is_save"]) {
            this.tooltipText = "Спасибо. Ваш прогноз сохранен";
          } else this.tooltipText = "Вы уже оставляли прогноз";
          this.votingUserType = result["data"]["type"];
          this.votingUserText = result["data"]["text"];
          this.isPopupCancel = false;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isOpenAuth = true;
        this.authText =
          "Авторизируйтесь, чтобы сохранить<br /> прогноз и участвовать в розыгрыше";
      }
    },
    async clickVotingSuccess() {
      if (this.votingSuccessValue2 > this.votingSuccessValue1) {
        this.tooltipText = "Время в движении должно быть меньше общего времени";
        return false;
      }

      if (this.authToken) {
        let profile = this.profile;
        if (!profile.firstName || !profile.lastName) {
          this.isOpenProfileName = true;
        }
        let data = {
          type: 2,
          text1: this.votingSuccessText1,
          text2: this.votingSuccessText2,
          auth_token: this.authToken,
        };
        try {
          const result = await api.shop.postVoting1111(data);
          if (result["data"]["is_save"]) {
            this.tooltipText = "Спасибо. Ваш прогноз сохранен";
          } else this.tooltipText = "Вы уже оставляли прогноз";
          this.votingUserType = result["data"]["type"];
          this.votingUserText = result["data"]["text"];
          this.isPopupSuccess = false;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isOpenAuth = true;
        this.authText =
          "Авторизируйтесь, чтобы сохранить<br /> прогноз и участвовать в розыгрыше";
      }
    },
    async getVoting1111() {
      try {
        const result = await api.shop.getVoting1111();
        if (result["data"]["type"]) {
          this.votingUserType = result["data"]["type"];
          this.votingUserText = result["data"]["text"];
        }
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    getUrl(value) {
      let text =
        "2024 года состоится челлендж Виктора Доронина в Дубаи. Виктор преодолеет 1111 километров — 10 км. вплавь, 1001 км. на велосипеде и 100 км бегом.%0AВиктор решил узнать пределы собственных.%0A%0AБренд UltraBalance снял 5 эпизодов про подготовку к челленджу. Они доступны по ссылке: https://vkvideo.ru/playlist/-224465212_3%0A%0A";
      text += value;
      text += "%0A%0AПроголосовать можно по ссылке ultrabalance.ru/1111";
      return text;
    },
  },
  created() {
    if (this.sourcePage === 5) this.articleId = this.page.id;
    for (let item in this.page.blocks) {
      item = this.page.blocks[item];
      if (item.type === 10) this.getBasketProduct(item.product);
      if (item.type === 16) {
        this.columns[item.id] = {};
        for (let column = 1; column <= Number(item.content); column += 1) {
          this.columns[item.id][column] = [];
        }
      }
      if (item.block_id) {
        this.columns[item.block_id][item.number_column].push(item);
      } else this.blocks.push(item);
    }
    let timeend = new Date(2024, 11, 24, 0, 0);
    function time() {
      let today = new Date();
      today = Math.floor((today - timeend) / 1000);
      let tsec = today % 60;
      today = Math.floor(today / 60);
      if (tsec < 10) tsec = "0" + tsec;
      let tmin = today % 60;
      today = Math.floor(today / 60);
      if (tmin < 10) tmin = "0" + tmin;
      let thour = today % 24;
      today = Math.floor(today / 24);
      document.getElementById("time_day").innerHTML = today;
      document.getElementById("time_hours").innerHTML = thour;
      document.getElementById("time_minute").innerHTML = tmin;
      window.setTimeout(time, 10000);
    }
    window.setTimeout(time, 1000);
    if (this.authToken) this.getVoting1111();
  },
  mounted() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        for (let item in that.blocks) {
          that.blocks[item].isOpen = false;
        }

        that.isPopupCancel = false;
        that.isPopupSuccess = false;
        that.isOpenAuth = false;
        that.isOpenProfileName = false;
        that.isOpenPopup(false);
      }
    });
  },
};
</script>
