<template>
  <div class="add-delivery-popup shop-popup">
    <div class="content">
      <img
        @click="$parent.isAddDelivery = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <div class="select-tab">
        <div
          :class="{ active: selectDeliveryType === 1 }"
          @click="selectDeliveryType = 1"
        >
          <span>Пункт выдачи</span>
          <span>Бесплатно</span>
        </div>
        <div
          :class="{ active: selectDeliveryType === 2 }"
          @click="selectDeliveryType = 2"
        >
          <span>Курьером</span>
          <span>199 руб.</span>
        </div>
      </div>
      <div v-if="selectDeliveryType === 1" class="delivery-points">
        <div id="map"></div>
        <div class="selected-point" v-if="selectedPoint">
          <span class="name" v-if="selectedPoint.is_postamat">
            Почтомат {{ selectedPoint.delivery_service_name }}
          </span>
          <span class="name" v-else>
            Пункт выдачи {{ selectedPoint.delivery_service_name }}
          </span>
          <img
            src="@/assets/images/shop-close.svg"
            @click="selectedPoint = null"
          />
          <span class="address">{{ selectedPoint.address }}</span>
          <span class="working">
            <span>Режим работы:</span>
            <span v-html="selectedPoint.working_hours"></span>
          </span>
          <button @click.stop="createDeliveryPoint">Выбрать</button>
          <span class="comment" v-if="selectedPoint.comment">
            {{ selectedPoint.comment }}
          </span>
        </div>
      </div>
      <div
        v-else-if="selectDeliveryType === 2 && isOpenCourierMap"
        class="delivery-points"
      >
        <div id="map2"></div>
        <img class="place-mark" src="@/assets/images/select-geo.svg" />
        <div
          class="select-geo"
          v-if="selectedGeoAddressNot && selectedGeoAddress"
        >
          <span>Нет курьерской доставки</span>
          <span>{{ selectedGeoAddress.value }}</span>
        </div>
        <div class="select-geo" v-else-if="selectedGeoAddressNot">
          <span>Нет курьерской доставки</span>
          <span>Не найдено</span>
        </div>
        <div
          class="select-geo selected"
          v-else-if="selectedGeoAddress"
          @click.stop="selectGeoAddress"
        >
          <span>Выбрать адрес</span>
          <span>{{ selectedGeoAddress.value }}</span>
        </div>
        <div class="select-geo" v-else>
          <span>Выбрать адрес</span>
          <span>Адрес не определен</span>
        </div>
      </div>
      <div v-else-if="selectDeliveryType === 2" class="delivery-courier">
        <div
          class="group-input"
          @click.stop="(this.isOpenCourierMap = true), selectYMaps2()"
        >
          <small>Адрес:</small>
          <input
            type="text"
            disabled="disabled"
            :value="selectedGeoAddress.value"
          />
          <button>Изменить</button>
        </div>
        <div class="group-input">
          <small>Квартира</small>
          <input
            type="number"
            placeholder="Если актуально"
            v-model="deliveryCourierFlat"
          />
        </div>
        <div class="group-input">
          <small>Комментарий курьеру</small>
          <textarea
            placeholder="Если необходим"
            v-model="deliveryCourierComment"
          ></textarea>
        </div>
        <button @click.stop="createDeliveryCourier">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import api from "@/api";

let myMap;
let myMap2;

export default {
  mixins: [mainMixin],
  props: {
    deliveriesPoints: String,
    isCreate: Boolean,
    isSettings: Boolean,
    isCheckout: Boolean,
  },
  data() {
    return {
      selectDeliveryType: 1,
      selectedPoint: null,
      deliveryCourierCity: "",
      deliveryCourierStreet: "",
      deliveryCourierHouse: "",
      deliveryCourierFlat: "",
      deliveryCourierZipCode: "",
      deliveryCourierGeoLat: "",
      deliveryCourierGeoLon: "",
      deliveryCourierComment: "",
      isInitYMaps: false,
      isInitYMaps2: false,
      isOpenCourierMap: true,
      selectedGeoAddressNot: false,
      selectedGeoAddress: null,
    };
  },
  async created() {
    if (this.$parent.editDelivery) {
      this.clickEditDelivery(this.$parent.editDelivery);
    } else this.clickAddDelivery();
  },
  methods: {
    async createDeliveryCourier() {
      let address_raw = this.selectedGeoAddress["value"];
      try {
        let data = {
          type: 2,
          address_raw: address_raw,
          city: this.deliveryCourierCity,
          street: this.deliveryCourierStreet,
          house: this.deliveryCourierHouse,
          zip_code: this.deliveryCourierZipCode,
          geo_lat: this.deliveryCourierGeoLat,
          geo_lon: this.deliveryCourierGeoLon,
        };
        if (!this.isCheckout && this.isCreate) data.is_default = true;
        if (this.deliveryCourierFlat) {
          data["flat"] = this.deliveryCourierFlat;
          data["address_raw"] += " - " + data["flat"];
        }
        if (this.deliveryCourierComment) {
          data["comment"] = this.deliveryCourierComment;
        }
        let create_item_id = new Date().getTime();
        if (this.isCreate) {
          if (this.authToken) {
            const result = await api.profile.postUserDelivery(data);
            create_item_id = result["data"]["id"];
            if (this.isSettings) {
              let item = {
                id: create_item_id,
                type: 2,
                city: data["city"],
                address_raw: data["address_raw"],
                pointId: data["point_id"],
                info_point: {},
                cost: 199,
                courier: {
                  city: data["city"],
                  street: data["street"],
                  house: data["house"],
                  flat: data["flat"],
                  zip_code: data["zip_code"],
                  geo_lat: data["geo_lat"],
                  geo_lon: data["geo_lon"],
                  comment: data["comment"],
                },
              };
              this.$parent.deliveries.push(item);
            }
          } else {
            localStorage.setItem("createDeliveryId", create_item_id);
            localStorage.setItem("createDeliveryType", 2);
            localStorage.setItem("createDeliveryCity", data["city"]);
            localStorage.setItem("createDeliveryStreet", data["street"]);
            localStorage.setItem("createDeliveryHouse", data["house"]);
            localStorage.setItem("createDeliveryFlat", data["flat"]);
            localStorage.setItem("createDeliveryZipCode", data["zip_code"]);
            localStorage.setItem("createDeliveryGeoLat", data["geo_lat"]);
            localStorage.setItem("createDeliveryGeoLon", data["geo_lon"]);
            localStorage.setItem("createDeliveryComment", data["comment"]);
            localStorage.setItem(
              "createDeliveryAddressRaw",
              data["address_raw"]
            );
          }
        }
        if (this.$parent.editDelivery) {
          await api.profile.putUserDelivery(
            this.$parent.editDelivery["id"],
            data
          );
          this.$parent.editDelivery["type"] = 2;
          this.$parent.editDelivery["address_raw"] = data["address_raw"];
          this.$parent.editDelivery["city"] = data["city"];
          this.$parent.editDelivery["info_point"] = {};
        } else if (this.isCheckout) {
          let item = {
            id: create_item_id,
            type: 2,
            city: data["city"],
            address_raw: data["address_raw"],
            pointId: data["point_id"],
            info_point: {},
            cost: 199,
            courier: {
              city: data["city"],
              street: data["street"],
              house: data["house"],
              flat: data["flat"],
              comment: data["comment"],
              zip_code: data["zip_code"],
              geo_lat: data["geo_lat"],
              geo_lon: data["geo_lon"],
            },
          };
          this.$parent.deliveries.push(item);
          this.$parent.selectDelivery = item;
          this.$parent.selectDeliveryId = item["id"];
        }
        if (!this.isCheckout && this.isCreate) {
          localStorage.setItem("city", data["city"]);
          this.$emit("changeCity", data["city"]);
          this.$emit("tooltipDelivery", true);
        }
        this.$parent.isAddDelivery = false;
      } catch (error) {
        console.log(error);
      }
    },
    async createDeliveryPoint() {
      try {
        let data = {
          type: 1,
          point_id: this.selectedPoint.id,
        };
        if (!this.isCheckout && this.isCreate) data.is_default = true;
        let create_item_id = new Date().getTime();
        if (this.isCreate) {
          if (this.authToken) {
            const result = await api.profile.postUserDelivery(data);
            create_item_id = result["data"]["id"];
            if (this.isSettings) {
              let item = {
                id: create_item_id,
                type: 1,
                city: null,
                address_raw: null,
                pointId: data["point_id"],
                info_point: this.selectedPoint,
                cost: this.selectedPoint["cost_of_delivery"],
                is_default: true,
              };
              let deliveries = this.$parent.deliveries;
              this.$parent.deliveries = [];
              this.$parent.deliveries.push(item);
              for (let delivery in deliveries) {
                deliveries[delivery].is_default = false;
                this.$parent.deliveries.push(deliveries[delivery]);
              }
            }
          } else {
            localStorage.setItem("createDeliveryId", create_item_id);
            localStorage.setItem("createDeliveryType", 1);
            localStorage.setItem("createDeliveryPointId", data["point_id"]);
          }
        }
        if (this.$parent.editDelivery) {
          await api.profile.putUserDelivery(
            this.$parent.editDelivery["id"],
            data
          );
          this.$parent.editDelivery["type"] = 1;
          this.$parent.editDelivery["address_raw"] = null;
          this.$parent.editDelivery["info_point"] = this.selectedPoint;
        } else if (this.isCheckout) {
          let item = {
            id: create_item_id,
            type: 1,
            city: null,
            address_raw: null,
            pointId: data["point_id"],
            info_point: this.selectedPoint,
            cost: this.selectedPoint["cost_of_delivery"],
          };
          this.$parent.deliveries.push(item);
          this.$parent.selectedPoint = null;
          this.$parent.selectDelivery = item;
          this.$parent.selectDeliveryId = item["id"];
        }
        if (!this.isCheckout && this.isCreate) {
          localStorage.setItem("city", this.selectedPoint["address_city"]);
          this.$emit("changeCity", this.selectedPoint["address_city"]);
          this.$emit("tooltipDelivery", true);
        }
        this.$parent.isAddDelivery = false;
      } catch (error) {
        console.log(error);
      }
    },
    initYMaps(lat, lon) {
      this.isInitYMaps = false;
      let block = document.querySelector("#map ymaps");
      if (block) block.remove();
      myMap = new window.ymaps.Map(
        "map",
        {
          center: [lat, lon],
          zoom: 14,
          controls: ["searchControl", "zoomControl", "geolocationControl"],
        },
        {
          searchControlProvider: "yandex#search",
        }
      );
      this.pointsYMaps();
    },
    initYMaps2(lat, lon) {
      let that = this;
      this.isInitYMaps2 = false;
      let block = document.querySelector("#map2 ymaps");
      if (block) block.remove();
      myMap2 = new window.ymaps.Map(
        "map2",
        {
          center: [lat, lon],
          zoom: 14,
          controls: ["searchControl", "zoomControl", "geolocationControl"],
        },
        {
          searchControlProvider: "yandex#search",
        }
      );
      myMap2.events.add("actionend", function () {
        let center = myMap2.getCenter();
        that.getAddress(center);
      });
    },
    async getAddress(coords) {
      this.selectedGeoAddressNot = false;
      this.selectedGeoAddress = null;
      try {
        const result = await api.shop.getAddressCoords(coords[1], coords[0]);
        if (result["data"]["values"].length > 0) {
          this.selectedGeoAddress = result["data"]["values"][0];
          this.deliveryCourierGeoLat = coords[0];
          this.deliveryCourierGeoLon = coords[1];
        }
        if (!result["data"]["is_boxberry"]) {
          this.selectedGeoAddressNot = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectGeoAddress() {
      this.isOpenCourierMap = false;
      this.deliveryCourierCity = this.selectedGeoAddress["data"]["city"];
      this.deliveryCourierStreet =
        this.selectedGeoAddress["data"]["street_with_type"];
      this.deliveryCourierHouse = "";
      if (this.selectedGeoAddress["data"]["house"]) {
        this.deliveryCourierHouse += this.selectedGeoAddress["data"]["house"];
      }
      if (this.selectedGeoAddress["data"]["block_type"]) {
        this.deliveryCourierHouse +=
          this.selectedGeoAddress["data"]["block_type"];
      }
      if (this.selectedGeoAddress["data"]["block"]) {
        this.deliveryCourierHouse += this.selectedGeoAddress["data"]["block"];
      }
      if (this.selectedGeoAddress["data"]["postal_code"]) {
        this.deliveryCourierZipCode +=
          this.selectedGeoAddress["data"]["postal_code"];
      }
    },
    pointsYMaps() {
      let points = this.deliveriesPoints.split(",");
      if (points.length === 0) return false;
      if (this.isInitYMaps) return false;
      this.isInitYMaps = true;
      let clusterer = new window.ymaps.Clusterer({
        preset: "islands#invertedVioletClusterIcons",
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
      });
      clusterer.events.add(["mouseenter", "mouseleave"], function (e) {
        let target = e.get("target");
        let type = e.get("type");
        if (typeof target.getGeoObjects !== "undefined") {
          if (type === "mouseenter") {
            target.options.set("preset", "islands#invertedPinkClusterIcons");
          } else {
            target.options.set("preset", "islands#invertedVioletClusterIcons");
          }
        } else {
          if (type === "mouseenter") {
            target.options.set("preset", "islands#pinkIcon");
          } else {
            target.options.set("preset", "islands#violetIcon");
          }
        }
      });
      let geoObjects = [];
      let that = this;
      myMap.events.add("mousedown", function () {
        that.selectedPoint = null;
      });
      let num = 0;
      for (let item in points) {
        item = points[item].split("-");
        if (item[3] === "p") continue;
        geoObjects[num] = new window.ymaps.Placemark(
          [Number(item[1]), Number(item[2])],
          {},
          {
            preset: "islands#violetIcon",
          }
        );
        geoObjects[num].events.add(["click"], function () {
          that.getInfoPoint(item[0]);
        });
        num += 1;
      }
      clusterer.add(geoObjects);
      myMap.geoObjects.add(clusterer);

      clusterer = new window.ymaps.Clusterer({
        preset: "islands#invertedDarkBlueClusterIcons",
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
      });
      clusterer.events.add(["mouseenter", "mouseleave"], function (e) {
        let target = e.get("target");
        let type = e.get("type");
        if (typeof target.getGeoObjects !== "undefined") {
          if (type === "mouseenter") {
            target.options.set("preset", "islands#invertedNightClusterIcons");
          } else {
            target.options.set(
              "preset",
              "islands#invertedDarkBlueClusterIcons"
            );
          }
        } else {
          if (type === "mouseenter") {
            target.options.set("preset", "islands#nightIcon");
          } else {
            target.options.set("preset", "islands#darkBlueIcon");
          }
        }
      });
      geoObjects = [];
      myMap.events.add("mousedown", function () {
        that.selectedPoint = null;
      });
      num = 0;
      for (let item in points) {
        item = points[item].split("-");
        if (item[3] === "b") continue;
        geoObjects[num] = new window.ymaps.Placemark(
          [Number(item[1]), Number(item[2])],
          {},
          {
            preset: "islands#darkBlueIcon",
          }
        );
        geoObjects[num].events.add(["click"], function () {
          that.getInfoPoint(item[0]);
        });
        num += 1;
      }
      clusterer.add(geoObjects);
      myMap.geoObjects.add(clusterer);
    },
    async getInfoPoint(point_id) {
      try {
        const result = await api.shop.getInfoDeliveryPoint(point_id);
        this.selectedPoint = result["data"]["results"][0];
      } catch (error) {
        console.log(error);
      }
    },
    clickEditDelivery(item) {
      this.selectDeliveryType = item["type"];
      if (item["type"] === 1) {
        this.deliveryCourierStreet = "";
        this.deliveryCourierHouse = "";
        this.deliveryCourierZipCode = "";
        this.deliveryCourierGeoLat = "";
        this.deliveryCourierGeoLon = "";
        this.deliveryCourierFlat = "";
        this.deliveryCourierComment = "";
        this.selectYMaps();
      } else {
        this.deliveryCourierCity = item["city"];
        this.deliveryCourierStreet = item["street"];
        this.deliveryCourierHouse = item["house"];
        this.deliveryCourierZipCode = item["zip_code"];
        this.deliveryCourierGeoLat = item["geo_lat"];
        this.deliveryCourierGeoLon = item["geo_lon"];
        this.deliveryCourierFlat = item["flat"];
        this.deliveryCourierComment = item["comment"];
      }
    },
    clickAddDelivery() {
      this.deliveryCourierStreet = "";
      this.deliveryCourierHouse = "";
      this.deliveryCourierZipCode = "";
      this.deliveryCourierGeoLat = "";
      this.deliveryCourierGeoLon = "";
      this.deliveryCourierFlat = "";
      this.deliveryCourierComment = "";
      this.selectDeliveryType = 1;
      this.selectYMaps();
    },
    selectYMaps() {
      let that = this;
      navigator.geolocation.getCurrentPosition(
        function (position) {
          let lat = position.coords.latitude;
          let lon = position.coords.longitude;
          setTimeout(function () {
            try {
              window.ymaps.ready(that.initYMaps(lat, lon));
            } catch (error) {
              console.log(error);
            }
          }, 200);
        },
        function (error) {
          console.log(error);
          setTimeout(function () {
            window.ymaps.ready(that.initYMaps(55.751574, 37.573856));
          }, 200);
        }
      );
    },
    selectYMaps2() {
      let that = this;
      navigator.geolocation.getCurrentPosition(
        function (position) {
          let lat = position.coords.latitude;
          let lon = position.coords.longitude;
          setTimeout(function () {
            try {
              window.ymaps.ready(that.initYMaps2(lat, lon));
            } catch (error) {
              console.log(error);
            }
          }, 200);
        },
        function (error) {
          console.log(error);
          setTimeout(function () {
            window.ymaps.ready(that.initYMaps2(55.751574, 37.573856));
          }, 200);
        }
      );
    },
  },
  watch: {
    selectDeliveryType(value) {
      if (value === 1) {
        this.selectYMaps();
      }
      if (value === 2) {
        this.selectYMaps2();
      }
    },
  },
};
</script>
